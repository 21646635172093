import React, {Fragment} from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Apple from "../../assets/LogosAd/apple.svg"
import Amazon from "../../assets/LogosAd/amazon.svg"
import Catter from "../../assets/LogosAd/caterpillar.svg"
import SpaceX from "../../assets/LogosAd/SpaceX.svg"
import CIA from "../../assets/LogosAd/CIA.svg"
import NASA from "../../assets/LogosAd/nasa.svg"
import Phizer from "../../assets/LogosAd/Pfizer.png"
import Sony from "../../assets/LogosAd/sony.svg"
import M from "../../assets/LogosAd/3m.svg"



export default function LeadsGenerated() {
  return (
    <Fragment>
     <section className='LeadsGeneratedSection'>
       <h1 className='LeadGeneratedTitle'>We have obtained leads from Forbes top companies for our clients, <span className='SpanInTitle'>we can do the same for your company </span> </h1>
      <div className='LeadsGeneratedTopCont'>
        <img src={Apple} className="LogoLetters"/>
        <img src={Amazon} className="LogoLetters AmazonLogo"/>
        <img src={Catter} className="LogoLetters"/>

        <img src={Phizer} className="Phizer"/>

      </div>
      <div className='LeadsGeneratedTopCont'>
   
      <img src={CIA} className="CIALogo"/>
      <img src={Sony} className="CIALogo"/>
      <img src={M} className="CIALogo"/>
      <img src={NASA} className="NasaLogo"/>

      </div>
     </section>

    </Fragment>
  )
}
