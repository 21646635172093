import React, {Fragment} from 'react'
import made from "../../assets/videos/Touch.mp4"

export default function How() {
  return (
    <Fragment>
        <section className='HowSectionContainer'>
            <section className='HowSectionContainerDescription'>
            <video src={made} playsInline autoPlay loop muted  type="video/mp4" className='HowVideo'/>

            <div data-aos="fade-up" className='HowInfoContainer'>
             <h1 className='PrincipalTitleHow'>Machine learning technology with the human touch. The best team algorithms and humans</h1>
             <p className='PrincipalHowDesc'>We know that each company is a single adventure, some are B2B another B2C, is for that reason that we do a personalized analysis and quote,to know which are the technologies that are going to take your business to the next level</p>
             <button className='HowBtn'>Get a free personalized quote</button>
         </div>
            </section>
        </section>
    </Fragment>
  )
}
