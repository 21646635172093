import React, { Fragment, useRef } from 'react'
import Email from "../../assets/Icons/email.png"
import Phone from "../../assets/Icons/Phone.png"
import F from "../../assets/Icons/facebook.png"
import I from "../../assets/Icons/instagram.png"
import In from "../../assets/Icons/InW.png"

import emailjs from '@emailjs/browser';
import Logo from "../../assets/Logos/l.svg"
export default function QuoteNow() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();


    emailjs.sendForm('service_6o7oxt3', 'template_nvz8ppk', form.current, '1ROy1Q5Iu7bDqNzE5')
      .then((result) => {
        console.log(result.text);
        console.log("messages sent")
        e.target.reset();
      }, (error) => {
        console.log(error.text);
      });
  };



  return (
    <Fragment>
      <section className='QuoteNowSectionContainer' id="QuoteNowID">


        <div className='QuoteNowFullContainer'>

          <div className='QuoteNowInfoContainer'>
            <h1 className='InfoQuoteTitle'>Get in touch with us</h1>
            <div className='TouchWithUsContainer'>
            <p className='QuoteNowDescriptionContact'><span><img className="QuoteIcon" src={Email}></img></span> Sales@Leadicts.com</p>
            <p className='QuoteNowDescriptionContact'><span><img className="QuoteIcon" src={Phone}></img></span> +404-905-9971</p>
            <p className='QuoteNowDescriptionContact'><span><img className="QuoteIcon" src={I}></img></span> Instagram</p>
            <p className='QuoteNowDescriptionContact'><span><img className="QuoteIcon" src={In}></img></span> LinkedIn</p>
            <p className='QuoteNowDescriptionContact'><span><img className="QuoteIcon" src={F}></img></span> Facebook</p>


            <p className='QuoteNowDescriptionText'>We have the fastest lead response in the market, we respond in less than 30 min once we receive your quote</p>
            </div>
          </div>

          <section className='QuoteFormContainer'>
            <form className='FormContainer' ref={form} onSubmit={sendEmail} data-aos="fade-up">
            <div className='InputContainer'><img src= {Logo} className="QuoteFormPiramide" data-aos="fade-up"/></div>


              <div  className='InputContainer'><input data-aos="fade-up" className='QuoteInput' placeholder='Full Name' type="text" name="to_name" /> </div>
              <div className='InputContainer InputContainerTwo'><input data-aos="fade-up" className='QuoteInput QuoteEmailInput' placeholder='Email' type="Email" name="from_email" /> </div>

              <div className='InputContainer InputContainerTwo'><input data-aos="fade-up" className='QuoteInput PreNumberQuote' placeholder='+' type="number" name="pre_phone" /> <input data-aos="fade-up" className='QuoteInput QuotePhoneInput' placeholder='Phone' type="number" name="my_phone" /> </div>

              <div > <textarea data-aos="fade-up" className="TextTareaInput" placeholder='Tell Us More About your company' name="message" /></div>

              <div className='InputContainer'><input type="submit" value="Send" className='QuoteSendBtn' />
              </div>
            </form>
          </section>
        </div>
      </section>
    </Fragment>
  )
}
