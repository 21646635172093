import React, {Fragment, useEffect} from 'react'
import AD1 from "../../assets/Illustration/AD2.png"
import Phone from "../../assets/Icons/Phone.png"

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function PrincipalCA() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


  return (
    <Fragment>
     <section className='CallToActionContainer'>

         <div data-aos="fade-up" className='CallToActionInformation'>
             <h1 className='PrincipalCallToActionTitle'>U.S. Based PPC Agency</h1>
             <p className='PrincipalCallDesc'>If You’re Looking For a PPC Agency to Get Your Phone Ringing Off The Hook, Then You’re in The Right Place.</p>
             <p className='PrincipalCallDesc'>consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </p>
             <div className=' CABtn'><img className='PhoneCallToAction' src={Phone} /> 404-905-9971</div>
         </div>
         <img  data-aos="fade-up" className='CallActionimg' src={AD1}></img>
     </section>
    </Fragment>
  )
}
