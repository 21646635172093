import React, { Fragment, useEffect } from 'react'
import { VideoHomeTools } from '../Tools'
import AdwordsVideoHome from "../../../assets/videos/Bing.mp4"
import { BlockHow, BlockHowTwo, CountUpAdwords, BlockHowCenter } from '../Tools'
import AD2 from "../../../assets/Illustration/BING1.svg"
import AD1 from "../../../assets/Illustration/BING2.svg"
import AD4 from "../../../assets/Illustration/BING3.svg"
import PartnerB from "../../PartnerBanner/PartnerB"
import { Helmet } from "react-helmet";
import NavLandingPageHome from "../../NewNavbar/NewNavbar"

export default function Bing() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LeadAddicts Bing</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <NavLandingPageHome></NavLandingPageHome>
      <VideoHomeTools
        V={AdwordsVideoHome}
        ToolsTitle="Bing Ads "
        ToolsDescription="Creating connections with the ideal customers for organizations, ensuring a flow of clients day by day, month by month, year by year."
        BtnDescriptionHome="Read More"
      />
      <BlockHow
        HowBlockTitle="Google Ads Management"
        HowBlockDescription="Pay Per Click Advertising or PPC is one of the greatest tools in the digital marketing arsenal, and one of the least understood.     "
        HowBlockDescriptionTwo="Often businesses shy away from PPC (Pay Per Click) because they don’t truly understand its effectiveness and fear the ROI to be less than profitable. Other companies have “tried” PPC in the past only to experience less than productive results. At The Mauldin Group we understand the mysterious world of PPC management and in particular the customized nuisances to make it succeed for YOUR business."
        Howbtn="Quote Now"
        Howimg={AD2}
      />
      <CountUpAdwords />

      <BlockHowTwo
        HowBlockTitle="Google Ads Management"
        HowBlockDescription="Pay Per Click Advertising or PPC is one of the greatest tools in the digital marketing arsenal, and one of the least understood.     "
        HowBlockDescriptionTwo="Often businesses shy away from PPC (Pay Per Click) because they don’t truly understand its effectiveness and fear the ROI to be less than profitable. Other companies have “tried” PPC in the past only to experience less than productive results. At The Mauldin Group we understand the mysterious world of PPC management and in particular the customized nuisances to make it succeed for YOUR business."
        Howbtn="Quote Now"
        Howimg={AD1}
      />

      <BlockHowCenter
        HowBlockTitleBold="70% of mobile searchers call a business directly from Google Search."
        HowBlockTitle="With an estimated 17.72 Billion smartphone users by 2024 mobile"
        Howimg={AD4}
        HowBlockDescription=". Customized PPC campaigns for mobile users are quickly becoming some the most in demand marketing needs requested from Internet marketing agencies. But the key is finding a team that can do it right."
      />
      <BlockHow
        HowBlockTitle="We focus in Google Ads Leads Generations"
        HowBlockDescription="Pay Per Click Advertising or PPC is one of the greatest tools in the digital marketing arsenal, and one of the least understood.     "
        HowBlockDescriptionTwo="Often businesses shy away from PPC (Pay Per Click) because they don’t truly understand its effectiveness and fear the ROI to be less than profitable. Other companies have “tried” PPC in the past only to experience less than productive results. At The Mauldin Group we understand the mysterious world of PPC management and in particular the customized nuisances to make it succeed for YOUR business."
        Howbtn="Quote Now"
        Howimg={AD4}
      />

    </Fragment>
  )
}
