import './App.css';
import { Fragment } from 'react';
import { Route, Routes } from "react-router-dom";

import LandingPageHome from './components/LandingPageHome/LandingPageHome';
import Services from './components/services/Services';
import PartnerB from './components/PartnerBanner/PartnerB';
import PrincipalCA from './components/PrincipalCallAction/PrincipalCA';
import Team from './components/Team/Team';
import Footer from './components/Footer/Footer';
import AboutUs from './components/About/AboutUs';
import How from './components/How/How';
import HowTwo from './components/HowTwo/HowTwo';
import Pricing from './components/Pricing/Pricing';
import QuoteNow from './components/ContactUs/QuoteNow';


//Adwords Section

import Adwords from "./components/Tools/Adwords/Adwords"
import Facebook from './components/Tools/Facebook/Facebook';
import Web from './components/Tools/Web/Web';
import SEO from './components/Tools/SEO/SEO';
import Bing from "./components/Tools/Bing/Bing"
import Linkedin from './components/Tools/Linkedin/Linkedin';
import Blog from './components/Blog/Blog';
import LeadsGenerated from './components/LeadsGenerated/LeadsGenerated';

function FullHome() {
  return (
    <Fragment>

      <LandingPageHome />
      <LeadsGenerated />
      <Services />
      <PrincipalCA />
      <How />
      <AboutUs></AboutUs>
      <HowTwo></HowTwo>
      <Team />
      <Pricing />
      <QuoteNow />
      <PartnerB />

      <Footer />



    </Fragment>
  )
}

function BlogSection() {
  return (
    <Fragment>
      <Blog />

    </Fragment>
  )
}
function LinkedinSection() {
  return (
    <Fragment>
      <Linkedin />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}

function FacebookSection() {
  return (
    <Fragment>
      <Facebook />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}


function SeoSection() {
  return (
    <Fragment>
      <SEO />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}

function AdwordsSection() {
  return (
    <Fragment>
      <Adwords />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}

function WebSection() {
  return (
    <Fragment>
      <Web />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}

function BingSection() {
  return (
    <Fragment>
      <Bing />
      <Pricing />
      <QuoteNow />
      <Footer />

    </Fragment>
  )
}


function App() {
  return (
    <Fragment>
      <Routes>

        <Route path="/" element={<FullHome></FullHome>} />
        <Route path="/HowAdwordsWorks" element={<AdwordsSection></AdwordsSection>} />
        <Route path="/HowFacebookAdsWorks" element={<FacebookSection></FacebookSection>} />
        <Route path="/WebDevelopment" element={<WebSection></WebSection>} />
        <Route path="/HowSeoWorks" element={<SeoSection></SeoSection>} />
        <Route path="/HowBingWorks" element={<BingSection></BingSection>} />
        <Route path="/HowLinkedinWorks" element={<LinkedinSection></LinkedinSection>} />
        <Route path="/LeadAddictsBlog" element={<BlogSection></BlogSection>} />

      </Routes>
    </Fragment>

  );
}

export default App;
