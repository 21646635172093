import React, { Fragment, useEffect } from 'react'
import RightArrow from "../../assets/Icons/WhiteArrow.png"
import RightArrowBlack from "../../assets/Icons/BlackArrow.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';


export const ServicesCard = (props) => {
    return (
        <div data-aos="fade-up" className='ServicesCard'>
            <p className='CardSubTitle'>{props.type}</p>
            <h2 className='CardServicesTitle'>{props.TechnologyTitle}</h2>
            <p className='CardServicesDescription'>{props.TechnologyDescription}</p>
            <p className="CardServicesbtn">View The Technology  <span className='ArrowServicesCont'><img className='RightArrowServices' src={RightArrow} /></span></p>
        </div>
    )
}


export default function Services() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


    return (
        <Fragment>
            <section className='SectionServicesContainer' id="ServicesID">

                <div className='CasesStudyContainer'>
                    <h1 className='ServicesContainerTitle'>Machine learning technology with the human touch. Top team, algorithms and humans</h1>
                    <div className='ServicesCardsContainer'>
                    <NavLink to="/HowAdwordsWorks" data-aos="fade-up" className='ServicesCard ServicesCardCenter'>
                            <p className='CardSubTitle'>TECHNOLOGY</p>
                            <h2 className='CardServicesTitle'>Google Ads</h2>
                            <p className='CardServicesDescription'>lorep imsum gene lorem im lorep imsum gene lorem im lorep imsum gene lorem im</p>
                            <p className="CardServicesbtn CardServicesbtncenter">View The Technology <span className='ArrowServicesCont'><img className='RightArrowServices' src={RightArrowBlack} /></span></p>
                        </NavLink>
                    </div>
                    <div className='ServicesCardsContainer'>
                        
                       
                        <NavLink to="WebDevelopment">
                            <ServicesCard
                                data-aos="fade-up"
                                type="SERVICE"

                                TechnologyTitle="Web Development"
                                TechnologyDescription="Web development tailor-made to get leads and phone rings"
                            />
                        </NavLink>

                        <NavLink to="/HowSeoWorks" data-aos="fade-up" className='ServicesCard ServicesCardCenter ServicesCardSecondRow'>
                            <p className='CardSubTitle'>SERVICE</p>
                            <h2 className='CardServicesTitle'>SEO </h2>
                            <p className='CardServicesDescription'>lorep imsum gene lorem im lorep imsum gene lorem im lorep imsum gene lorem im</p>
                            <p className="CardServicesbtn CardServicesbtncenter CSSecondRow">View The Technology <span className='ArrowServicesCont'><img className='RightArrowServices' src={RightArrow} /></span></p>
                        </NavLink>

                    </div>

                    <div className='ServicesCardsContainer'>
                        <NavLink to ="/HowLinkedinWorks">
                            <ServicesCard
                                data-aos="fade-up"
                                type="TECHNOLOGY"

                                TechnologyTitle="LinkedIn Ads"
                                TechnologyDescription="lorep imsum gene lorem im lorep imsum gene lorem im lorep imsum gene lorem im"
                            />
                            </NavLink>
                        <NavLink to="/HowSeoWorks">
                        <NavLink to="/HowFacebookAdsWorks">
                            <ServicesCard
                                data-aos="fade-up"
                                type="TECHNOLOGY"

                                TechnologyTitle="Facebook Ads"
                                TechnologyDescription="lorep imsum gene lorem im lorep imsum gene lorem im lorep imsum gene lorem im"
                            />
                        </NavLink>
                        
                        </NavLink>
                        <NavLink to="/HowBingWorks">
                        <ServicesCard
                            data-aos="fade-up"
                            type="TECHNOLOGY"
                            TechnologyTitle="Bing Ads"
                            TechnologyDescription="lorep imsum gene lorem im lorep imsum gene lorem im lorep imsum gene lorem im"
                        />
                        </NavLink>
                       
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
