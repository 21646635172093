import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/Logos/Lead.svg"
import { NavLink } from "react-router-dom";
import piramide from "../../assets/Logos/l.svg"
import "./NavBar.css"


const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: transparent;
  z-index:99;
  opacity:30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
`;



const Menu = styled.ul`
  list-style: none;
  display: flex;

  li:nth-child(2) {
    margin: 0px 20px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Item = styled.li`
  
  margin:20px !important;



     
`;



const NavIcon = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #fff;
  transition: width 0.4s ease-in-out;

  :nth-child(2) {
    width: ${props => (props.open ? "40%" : "70%")};
  }
`;

const Overlay = styled.div`
  position: fixed;
  height: ${props => (props.open ? "100vh" : 0)};
  width: 100vw;
  background: #000;
  transition: height 0.4s ease-in-out;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`  position:;

  list-style: none;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -100%);

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 50px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;

const Header = () => {

    //Scroll Top Effect

  
    //Scroll

    const goToTop = () => {
      
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
            
        });

        toggleNav(!toggle)
    };

    const goToPri = () => {

        window.scrollTo({
            top: (200, 5150),

            behavior: "smooth"
        })

        toggleNav(!toggle)
    };


    const goToContact = () => {

        window.scrollTo({
            top: (200, 5900),

            behavior: "smooth"
        })

        toggleNav(!toggle)
    };

    //Toggle
    const [toggle, toggleNav] = useState(false);




    //NavBar

    const [navbar, setNavbar] = useState(false)


    //Background ChangeColor


    const changeBackground = () => {

        if (window.scrollY >= 50) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }

    }

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <Nav id="NavBarContainer" className={navbar ? "NavBarContainer NavBarActivate" : "NavBarContainer"}>
                <div className="NavBarItemsFullContainer">
                    <div className="NavBarLogoContainer">
                        <div className="Logo-container-navbar">
                            <div
                                onClick={() => goToTop()}
                                style={{ "cursor": "pointer" }}
                            ><img alt="" src={Logo} className={navbar ? "Navbar-logo Nav-logo-active" : "Navbar-logo"} /></div>
                        </div>
                    </div>

                    <Menu id="PrincipalMenuNavBar">
                        <Item id="NavLinksContainer">
                            <NavLink className="Nav-Link" exact to="/" activeClassName="TabActive">Home</NavLink>
                            <div className="Nav-Link" exact onClick={() => goToPri()} activeClassName="TabActive">Pricing</div>
                            <NavLink className="Nav-Link" exact to="/LeadAddictsBlog" activeClassName="TabActive">Blog</NavLink>
                            <div onClick={() => goToContact()}  className="Nav-Link" exact to="/" activeClassName="TabActive" >Contact</div>
                            <div  onClick={() => goToContact()} className={navbar ? "Nav-Link divQuoteActivate" : "Nav-Link NavLinkQuote"}   >Quote Now</div>


                        </Item>



                    </Menu>
                    <NavIcon onClick={() => toggleNav(!toggle)} id="NavIcon">
                        <Line open={toggle} />
                        <Line open={toggle} />
                        <Line open={toggle} />
                    </NavIcon>
                </div>
            </Nav>

            <Overlay className="Overlay" open={toggle}>
                <OverlayMenu className="OverlayMenu" open={toggle} >
                    <NavIcon onClick={() => toggleNav(!toggle)}>

                        {/*
            <Line open={toggle} />
            <Line open={toggle} />
            <Line open={toggle} /> */}

                    </NavIcon>
                    <img className="MobileLogo" style={{ color: "white", cursor: "pointer" }} src={piramide} />

                    <Item>
                        <NavLink className="Nav-Link" to="/" onClick={() => toggleNav(!toggle)}>Home</NavLink>

                    </Item>
                    <Item>

                        <div className="Nav-Link"  onClick={() => goToPri()}>Pricing</div>


                    </Item>
                    <Item>
                        <NavLink className="Nav-Link" to="/LeadAddictsBlog" onClick={() => toggleNav(!toggle)}>Blog</NavLink>
                    </Item>

                    <Item>
                        <div className="Nav-Link"  onClick={() => goToContact()}>Contact</div>
                    </Item>
                    <Item>
                        <div className="Nav-Link "  onClick={() => goToContact()}>Quote</div>
                    </Item>

                </OverlayMenu>
            </Overlay>

        </>
    );
};

export default Header;

