import React, { Fragment, useEffect,useRef } from 'react'
import videoBg from '../../assets/videos/Video.mp4'
import DownArrow from "../../assets/Icons/down-arrow.svg"
import Logo from "../../assets/Logos/WhiteLead.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'

export default function Video() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const ref = useRef()


    return (
        <Fragment>
           
            <section className='VideoHomeContainer'>
                <div className='main'>
                   
                        <div className="overlay"></div>

                        <video src={videoBg} playsInline autoPlay loop muted type="video/mp4" />
                        <div className="content" data-aos="fade-up">
                            <img src={Logo} className='PrincipalLogoHome'></img>
                            <p className='LandingPageSubTitle'>Generating more than 1M dollars for our clients, creating connections with the ideal customers for organizations, ensuring a flow of clients  day by day, month by month, year by year with Google Ads.</p>
                            <div className='containerDownArrow'><img className='DownArrowLandingPage' src={DownArrow}></img></div>

                        </div>


                </div>
            </section>

         
        </Fragment>
    )
}
