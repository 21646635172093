import React, { Fragment } from 'react'
import RightArrowBlack from "../../assets/Icons/right-arrowblack.png"
import RightArrow from "../../assets/Icons/right-arrow.png"
import Check from "../../assets/Icons/check.png"
import AD5 from "../../assets/Illustration/AD8.png"
import Phone from "../../assets/Icons/Phone.png"

const ServicesCard = (props) => {
    return (
        <div data-aos="fade-up" className='ServicesCardPricing'>
            <p className='CardSubTitle'>{props.type}</p>
            <h2 className='CardServicesTitle'>{props.TechnologyTitle}</h2>
            <p className='CardServicesDescription'>{props.TechnologyDescription}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" /> <p>{props.ServicesOne}</p></p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesTwo}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesThree}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesFour}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesFive}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesSix}</p>
            <p className='CardServicesDescription EServicesCardPricing'><img src={Check} className="PricingCheck" />{props.ServicesSeven}</p>

            <p className="CardPricingBtn">Free Quote</p>
        </div>
    )
}


export default function Pricing() {
    return (
        <Fragment>

            <section className='SectionPricingFullContainer' >
                <h1 className='PricingSectionTitleBold'>We know that each company is a single adventure, <span className='PricingTitleNormal'>is for that reasons that we have packages based on your company size</span></h1>
            {/* <h1 className='PlansTitle'>Plans</h1>
            <section className='PricingContainerSection'>
                <ServicesCard
                    type="$250K - $500K Annual Revenues"
                    TechnologyTitle="Accel"
                    ServicesOne="Google AdWords Management"
                    ServicesTwo="Online Reputation Management"

                    ServicesThree="Monthly Marketing Consultation"
                    ServicesFour="Search Engine Optimization"

                    ServicesFive="Next Plan"
                    ServicesSix="Next Plan"
                    ServicesSeven="Next Plan"

                />
                <ServicesCard
                    type="$500K - $1M Annual Revenues"
                    TechnologyTitle="Ascend"
                    ServicesOne="Google AdWords Management"
                    ServicesTwo="Search Engine Optimization"
                    ServicesThree="Online Reputation Management"
                    ServicesFour="Monthly Marketing Consultation"
                    ServicesFive="Social Media Management"
                    ServicesSix="Email Marketing"
                    ServicesSeven="Next Plan"

                />
                <ServicesCard
                    type="$1M - $10M+ Annual Revenues"
                    TechnologyTitle="Advanced"
                    ServicesOne="Google AdWords Management"
                    ServicesTwo="Search Engine Optimization"
                    ServicesThree="Online Reputation Management"
                    ServicesFour="Monthly Marketing Consultation"
                    ServicesFive="Social Media Management"
                    ServicesSix="Email Marketing"
                    ServicesSeven="Content Marketing"

                />

            </section>

            <section className='CustomPlanContainer'>
             <div className='CustomPlanInfoContainer'>
             <h1 className='PricingSectionCustomTitle'>Do you want a custom plan for a specific niche ?</h1>
            <p className='CustomDescriptionPricing'>We can create a personalized plan based on your needs, we have the necessary technologies to reach the goal</p>
            <button className='FreeQuoteBtn'> <img  className='PricingQuoteBtn' src={Phone} /> +404-905-9971</button>
            </div>
            <img className='CustomQuoteImg' src={AD5}></img>
            </section>*/}
                
            </section>
        </Fragment>
    )
}
