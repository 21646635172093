import React, {Fragment, useEffect} from 'react'
import { VideoHomeTools } from '../Tools'
import AdwordsVideoHome from "../../../assets/videos/SEO.mp4"
import { BlockHow, BlockHowTwo, CountUpAdwords, BlockHowCenter } from '../Tools'
import AD2 from "../../../assets/Illustration/GO1.png"
import AD1 from "../../../assets/Illustration/GO2.png"
import AD4 from "../../../assets/Illustration/GO3.png"
import PartnerB from "../../PartnerBanner/PartnerB"
import { Helmet } from "react-helmet";

import NavLandingPageHome from "../../NewNavbar/NewNavbar"


export default function SEO (){

  useEffect(() => {
    window.scrollTo(0, 0)
}, [])

  return (
    <Fragment>
        <Helmet>
        <meta charSet="utf-8" />
        <title>LeadAddicts SEO</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
        <NavLandingPageHome></NavLandingPageHome>
        <VideoHomeTools
        V={AdwordsVideoHome}
        ToolsTitle="SEO Development"
        ToolsDescription="Gaining Visibility Online"
        BtnDescriptionHome="Read More"
        />
                <BlockHow
        HowBlockTitle="Why Are SEO and Organic Search Important?"
        HowBlockDescription="The importance of SEO is not something to overlook. Search engines employ specific algorithms and KPIs to direct online traffic to one site or another.  "
        HowBlockDescriptionTwo="For starters, SEO stands for search engine optimization, and it’s the process of improving a website in ways that help it rank in search results when users search for specific words and phrases.

        "
        Howbtn="Quote Now"
        Howimg={AD2}
      />
      <CountUpAdwords />

      <BlockHowTwo
        HowBlockTitle="The Components Of A Winning Inbound Marketing Strategy"
        HowBlockDescription="Ranking at the top of Google is a goal for many businesses. The problem is knowing how to do this and ensuring you rank for what your clients are searching for.

        "
        HowBlockDescriptionTwo="To create a winning strategy, you must know what best practices will get your posts to the top of the SERPs. However, this isn’t good enough. You must also choose the right terms to rank for.
        "
        Howbtn="Quote Now"
        Howimg={AD1}
      />

      <BlockHowCenter
        HowBlockTitleBold="To put a number on it, Google was visited 89.3 billion times in the past month alone "
        HowBlockTitle="SEO boosts your credibility"
        Howimg={AD4}
        HowBlockDescription=". Customized PPC campaigns for mobile users are quickly becoming some the most in demand marketing needs requested from Internet marketing agencies. But the key is finding a team that can do it right."
      />
      <BlockHow
        HowBlockTitle="SEO drives high-quality traffic to your website"
        HowBlockDescription="SEO also helps you earn more high-quality traffic to your website. Unlike traditional marketing, digital marketing offers hyper-targeted tools. Instead of paying for a billboard and hoping that your target audience sees it, you know they’ll see your website in search results.

        "
        HowBlockDescriptionTwo="With Google Analytics, as well as other web analytics tools, it’s easy to assess the performance of your SEO strategies. A few metrics to monitor include your website traffic, bounce rate, conversions, and time on site."
        Howbtn="Quote Now"
        Howimg={AD4}
      />

    </Fragment>
  )
}
