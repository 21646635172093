import React, {Fragment, useEffect} from 'react'
import Andrea from "../../assets/Avatars/Andrea.jpg"
import John from "../../assets/Avatars/John.jpg"
import Diego from "../../assets/Avatars/Diego.jpg"
import Linkedin from "../../assets/Icons/linkedin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

export const BannerTeam = () => {
  return (
    <section data-aos="fade-up">
        
        <h1 className='BannerTeamTitle'> <span className='HighLightBlack'>We will help your phone rings faster. </span>Our team of experts is ready to connect your company with ideal customers worldwide.</h1>
    </section>
  )
}


export const TeamCard = (props) => {
  return (
    <section data-aos="fade-up" className='AvatarCard'>
     <img src={props.avatarimg} className="AvatarTeamImg"></img>
     <h2 className='AvatarName'>{props.name}</h2>
     <a target="_blank" href={props.atag}><img src={Linkedin} className="LinkedinIcon"></img></a>
    </section>
  )
}


export default function Team() {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


  return (
    <Fragment>
        <section className='TeamSectionContainer'>
        <BannerTeam></BannerTeam>
        <h1 data-aos="fade-up" className='PrincipalTitleTeamSection'>Our Team</h1>
        <p data-aos="fade-up" className='TeamSubtitle'>The "Connectors"</p>
        <section className='ContainerAvatars'>
        <TeamCard atag="https://www.linkedin.com/in/john-shults-14419580/" avatarimg={John} name="John"></TeamCard>
        <TeamCard atag="https://www.linkedin.com/in/andrea-fergusson-101266226/" avatarimg={Andrea} name="Andrea"></TeamCard>
        <TeamCard  atag="https://www.linkedin.com/in/programador-fergusson-613a8220a/"  avatarimg={Diego} name="Diego"></TeamCard>

        </section>
        </section>
        
    </Fragment>
  )
}
