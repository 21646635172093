import React, {Fragment} from 'react'
import logo from "../../assets/Logos/Lead.svg"

export default function Footer() {

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return (
    <Fragment>
        <section className='FooterSectionContainer'>

          <section className='FooterColumnsContainer'>

            <div className='FooterColumnLogo'>
              <h1 className='LogoFooter' onClick={() => goToTop()}><img className=' FooterLogo' src={logo}></img></h1>
            </div>
            
            {/*  <div className='FooterColumn'>
              <h1 className='TitleFooter'>Go</h1>
              <p className='LinkFooter'>Customer Service</p>
              <p  className='LinkFooter'>Pricing</p>

            </div> */}
          

            <div className='FooterColumn'>
              <h1 className='TitleFooter'>About Us</h1>
              <p  className='LinkFooter'>Talk to sales</p>
              <p  className='LinkFooter'>Case Studies</p>
              <p  className='LinkFooter'>Blog</p>

            </div>

            <div className='FooterColumn'>
              <h1 className='TitleFooter'>Company</h1>
              <p  className='LinkFooter'>How it's works?</p>
              <p  className='LinkFooter'>Privacy Policy</p>
              <p  className='LinkFooter'>Services</p>
     

            </div>

            <div className='FooterColumn'>
              <h1 className='TitleFooter'>Connect with us</h1>
              <p className='LinkFooter' >Facebook</p>
              <p  className='LinkFooter'>Instagram</p>
              <p  className='LinkFooter'>LinkedIn</p>


            </div>
          </section>
        </section>
        <p className='RightReservedFooter'>© Leadicts All Rights Reserved</p>

    </Fragment>
  )
}
