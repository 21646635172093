import React, { Fragment, useEffect } from 'react'
import { VideoHomeTools } from '../Tools'
import AdwordsVideoHome from "../../../assets/videos/Linkedin.mp4"
import { BlockHow, BlockHowTwo, CountUpAdwords, BlockHowCenter } from '../Tools'
import AD2 from "../../../assets/Illustration/IN1.png"
import AD1 from "../../../assets/Illustration/IN2.png"
import AD4 from "../../../assets/Illustration/IN3.png"
import PartnerB from "../../PartnerBanner/PartnerB"
import { Helmet } from "react-helmet";
import NavLandingPageHome from '../../NewNavbar/NewNavbar'


export default function Linkedin() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <Fragment>
        <Helmet>
        <meta charSet="utf-8" />
        <title>LeadAddicts LinkedIn</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <NavLandingPageHome></NavLandingPageHome>
      
      <VideoHomeTools
        V={AdwordsVideoHome}
        ToolsTitle="LinkedIn Management"
        ToolsDescription="Reach your ideal customers on the world's largest professional network"
        BtnDescriptionHome="Read More"
        
      />
      <BlockHow
        HowBlockTitle="LinkedIn Management"
        HowBlockDescription="LinkedIn is used on a monthly basis by over 21.4 million U.S. visitors and over 47.6 million globally. LinkedIn profiles are highly authoritative within Google, meaning they have a positive impact on your search engine optimisation (SEO), this high-quality link from your LinkedIn profile to your website will likely help you reach higher in results pages on Google."
        HowBlockDescriptionTwo="Think of LinkedIn as your online business card, a place for you to showcase exactly what you want people to know about your business. A LinkedIn company page gives a business an opportunity to promote its products and services, recruit new talent, and share important, interesting and useful updates. A LinkedIn company page will help you to build your business or brand on the network, whereas a LinkedIn profile should be created to introduce your individual skills and experience."
        Howbtn="Quote Now"
        Howimg={AD2}
      />
      <CountUpAdwords />

      <BlockHowTwo
        HowBlockTitle="The most effective place for B2B marketers"
        HowBlockDescription="LinkedIn is a powerhouse social network for B2B marketers. Built for business networking, LinkedIn works exceptionally well for B2B lead generation, making it one of the top tactics for B2B marketing.     "
        HowBlockDescriptionTwo="The real question is: how well does LinkedIn perform for lead generation? The answer: better than all other networks. According to HubSpot, LinkedIn is 277% more effective at lead generation than Facebook and Twitter."
        Howbtn="Quote Now"
        Howimg={AD1}
      />

      <BlockHowCenter
        HowBlockTitleBold="More than 740 million people are on LinkedIn."
        HowBlockTitle="9.70% of B2B marketers are planning on increasing their organic activities on LinkedIn in 2022"
        Howimg={AD4}
        HowBlockDescription=". Customized PPC campaigns for mobile users are quickly becoming some the most in demand marketing needs requested from Internet marketing agencies. But the key is finding a team that can do it right."
      />
      <BlockHow
        HowBlockTitle="Network, network, network!"
        HowBlockDescription="LinkedIn is the world’s most extensive professional network, Top talent throughout the world uses LinkedIn, and the platform makes it incredibly simple to connect with potential customers and grow your professional network while doing so.        "
        HowBlockDescriptionTwo="Using LinkedIn company page features allows a business to reaches its target audience. A company page can also be used to promote your brand’s core values, nurture leads and of course, improve the visibility of any job opportunities currently available.

        "
        Howbtn="Quote Now"
        Howimg={AD4}
      />

    </Fragment>
  )
}
