import React, {Fragment, useEffect} from 'react'
import { VideoHomeTools } from '../Tools'
import AdwordsVideoHome from "../../../assets/videos/KeyboardWhite.mp4"
import { BlockHow, BlockHowTwo, CountUpAdwords, BlockHowCenter } from '../Tools'
import AD2 from "../../../assets/Illustration/FB1.png"
import AD1 from "../../../assets/Illustration/FB2.png"
import AD4 from "../../../assets/Illustration/FB3.png"
import { Helmet } from "react-helmet";

import NavLandingPageHome from "../../NewNavbar/NewNavbar"




export default function Facebook (){

  useEffect(() => {
    window.scrollTo(0, 0)
}, [])


  return (
    <Fragment>
           <Helmet>
        <meta charSet="utf-8" />
        <title>LeadAddicts Facebook</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
        <NavLandingPageHome></NavLandingPageHome>
        <VideoHomeTools
        V={AdwordsVideoHome}
        ToolsTitle="Facebook Ads"
        ToolsDescription="A direct way to connect with current and potential customers."
        BtnDescriptionHome="Read More"
        />
           <BlockHow
        HowBlockTitle="Facebook Ads Management"
        HowBlockDescription="Your Facebook for Business page can help you create digital word of mouth. Managed well, your page acts as a forum for community growth and communication with new and loyal customers."
        HowBlockDescriptionTwo="While skewing towards younger users, with 62% of users falling between 18 and 34 years old, Facebook attracts users of all generations, with 38% of falling from 35 all the way to 65+."
        Howbtn="Quote Now"
        Howimg={AD2}
      />
      <CountUpAdwords />

      <BlockHowTwo
        HowBlockTitle="Alignment with Both B2C & B2B Businesses"
        HowBlockDescription="Business decision-makers spend 74% more time on Facebook than other people. Business to business (B2B) and business to consumer (B2C) companies are relying more and more on social media to meet marketing goals. With more than two billion people active on social media worldwide, it has become a crucial tool."
        HowBlockDescriptionTwo="B2C companies on the other hand are driven to influence individual customers specifically to purchase something. Social media marketing is ideal for such companies, as they can reach a large audience. In a study by Clutch, nearly 60 percent of the B2C companies surveyed reported that social media helps increase their revenue and sales, compared to 46 percent of B2B companies. Additionally, 96 percent of those B2C companies cited Facebook as the most valuable social media platform to meet their specific goals."
        Howbtn="Quote Now"
        Howimg={AD1}
      />

      <BlockHowCenter
        HowBlockTitleBold="160 million businesses and 2.89 BILLION users are on Facebook MONTHLY."
        HowBlockTitle="reach BILLIONS of potential customers"
        Howimg={AD4}
        HowBlockDescription=". Customized PPC campaigns for mobile users are quickly becoming some the most in demand marketing needs requested from Internet marketing agencies. But the key is finding a team that can do it right."
      />
      <BlockHow
        HowBlockTitle="BILLION USERS!"
        HowBlockDescription="Yes, there are over a billion people on Facebook, but you don’t need to reach all of them — just those in your sweet spot. Facebook for Business gives you the ability to choose specific audiences to reach with your boosted posts by their geographic location, age, interests, and more."
        HowBlockDescriptionTwo="Whether you have an eCommerce business or a brick-and-mortar store, there are endless benefits to mobile-first capabilities. Your Facebook page will house important business information for potential customers on their mobile devices."
        Howbtn="Quote Now"
        Howimg={AD4}
      />

    </Fragment>
  )
}
