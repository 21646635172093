import React, { Fragment } from 'react'
import GoogleParner from "../../assets/Logos/Gpremier.jpg"
import Phone from "../../assets/Icons/Phone.png"

export default function PartnerB() {
  return (
    <Fragment>
        <section className='PartnerBGoogleContainer'>
         <div className='PartnerBGoogleInfoCont'>
             <img src={GoogleParner}></img>
             <div className='DescriptionBGoogle'>
                 <h1>Google Premier Partner Agency</h1>
                 <p className='DescriptionPartner'>The highest level an agency can achieve is Premier Partner, a designation reserved for only a small fraction of all Google Partners. Being a Premier Partner means an agency is knowledgeable, experienced, and has a proven track record of success.</p>
                 <button className="PartnerBtn "><img className='PhoneCallToAction PhoneBtnGP' src={Phone} />  +404-905-9971</button>
             </div>
         </div>
        </section>
    </Fragment>
  )
}
