import React, { Fragment } from 'react'
import X from "../../assets/images/X.png"
import Typewriter from 'typewriter-effect';

function AboutUs() {
    return (
        <Fragment>
            <section className='AboutUsSectionContainer'>
                <div className='AboutUsInfoContainer'>
                    <h1 className='PrincipalTitleAboutUs'>About Us, The  <div className='Type'><Typewriter
                        options={{
                            strings: ['X Factor', 'Curiosity', 'Competence ', 'Character'],
                            autoStart: true,
                            loop: true,
                        }}
                    />                    </div>
                    </h1>

                    <p className='AboutUsDescription'>LoreSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div><img src={X} className="AboutUsImg"></img></div>
            </section>
        </Fragment>
    )
}

export default AboutUs