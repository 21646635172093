import React, { Fragment, useEffect } from 'react'
import { VideoHomeTools } from '../Tools/Tools'
import AdwordsVideoHome from "../../assets/videos/Blog.mp4"
import NavLandingPageHome from "../NewNavbar/NewNavbar"


import {Helmet} from "react-helmet";

export default function Blog() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>LeadAddicts Blog</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <NavLandingPageHome />
            <VideoHomeTools
                V={AdwordsVideoHome}
                ToolsTitle="Leaddicts Blog"
                ToolsDescription="Creating connections with the ideal customers for organizations, ensuring a flow of clients day by day, month by month, year by year."
                BtnDescriptionHome="Read More"
            />
        </Fragment>
    )
}
