import React, { Fragment } from 'react'
import DownArrow from "../../assets/Icons/down-arrow.svg"
import CountUp from 'react-countup';

export function VideoHomeTools(props) {
  return (
    <Fragment>
      <section className='VideoHomeContainer'>
        <div className='main'>
          <div className="overlay"></div>
          <video src={props.V} playsInline autoPlay loop muted type="video/mp4" />
          <div className="content" >
            <h1 className='LandingPageTitle'>{props.ToolsTitle}</h1>
            <p className='LandingPageSubTitle'>{props.ToolsDescription}</p>
            <button className='LandingPageBtn'>{props.BtnDescriptionHome}</button>
            <div className='containerDownArrow'><img className='DownArrowLandingPage' src={DownArrow}></img></div>

          </div>


        </div>
      </section>
    </Fragment>
  )
}


export function BlockHow(props) {
  return (
    <section className='CallToActionContainer'>

      <div className='CallToActionInformation'>
        <h1 className='PrincipalCallToActionTitle'>{props.HowBlockTitle}</h1>
        <p className='PrincipalCallDesc'>{props.HowBlockDescription}</p>
        <p className='PrincipalCallDesc'>{props.HowBlockDescriptionTwo}</p>
        <button className='PartnerBtn CABtn'>{props.Howbtn}</button>
      </div>
      <img className='CallActionimg' src={props.Howimg}></img>
    </section>
  )
}


export function BlockHowTwo(props) {
  return (
    <section className='CallToActionContainer BLockHowTwoContainer'>

      <img className='CallActionimg' src={props.Howimg}></img>

      <div className='CallToActionInformation'>
        <h1 className='PrincipalCallToActionTitle'>{props.HowBlockTitle}</h1>
        <p className='PrincipalCallDesc'>{props.HowBlockDescription}</p>
        <p className='PrincipalCallDesc'>{props.HowBlockDescriptionTwo}</p>
        <button className='PartnerBtn CABtn'>{props.Howbtn}</button>
      </div>
    </section>
  )
}

export function BlockHowCenter(props) {
  return (
    <section className='BlockHowCenterContainer'>
      <div className='BlockHowCenterContainerInfo'>
        <h1 className='BlockHowCenterTitleBold'>{props.HowBlockTitleBold} <h1 className='BlockHowCenterTitle'>{props.HowBlockTitle}</h1> </h1>
      </div>


    </section>
  )
}

export function CountUpAdwords() {
  return (
    <Fragment>
      <section className='CountUpContainer'>
        <CountUp
          start={0}
          end={1043}
          duration={2.75}
          delay={5}

        >
          {({ countUpRef, start }) => (
            <div className='CountUpContainer'>
              <div className='CountUpCard'>
                <span className='CountUpNumber'  > Form Leads </span>
                <span className='CountUpNumber' ref={countUpRef} />
              </div>

            </div>
          )}
        </CountUp>
        <CountUp
          start={0}
          end={534}
          duration={2.75}
          delay={5}

        >
          {({ countUpRef, start }) => (
            <div className='CountUpContainer'>
              <div className='CountUpCard'>
                <span className='CountUpNumber'  > Calls </span>
                <span className='CountUpNumber' ref={countUpRef} />
              </div>

            </div>
          )}
        </CountUp>

        <CountUp
          start={0}
          end={86}
          duration={2.75}
          delay={5}

        >
          {({ countUpRef, start }) => (
            <div className='CountUpContainer'>
              <div className='CountUpCard'>
                <span className='CountUpNumber'  > Campaings </span>
                <span className='CountUpNumber' ref={countUpRef} />
              </div>

            </div>
          )}
        </CountUp>

        <CountUp
          start={0}
          end={213}
          duration={2.75}
          delay={5}

        >
          {({ countUpRef, start }) => (
            <div className='CountUpContainer'>
              <div className='CountUpCard'>
                <span className='CountUpNumber'>  Ads </span>
                <span className='CountUpNumber' ref={countUpRef} />
              </div>

            </div>
          )}
        </CountUp>
      </section>
    </Fragment>
  )
}
export default function Tools() {
  return (
    <div>Tools</div>
  )
}
