import React, { Fragment, useEffect } from 'react'
import Video from '../VHomePage/Video'
import { Helmet } from "react-helmet";
import NewNavBar from "../NewNavbar/NewNavbar"
export const LandingPageHome = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LeadAddicts</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <NewNavBar></NewNavBar>
      <Video></Video>
    </Fragment>
  )
}

export default LandingPageHome